const storageKey = "gameUserIds";

export const getUserIdForGame = (gameId) => {
  const gameUserIds = JSON.parse(localStorage.getItem(storageKey));

  if (!gameUserIds) return null;

  const gameUserId = gameUserIds.find((guid) => guid.startsWith(`${gameId}#`));

  if (!gameUserId) return null;

  return gameUserId.split("#")[1];
};

export const setUserIdInGame = (gameId, userId) => {
  const gameUserId = `${gameId}#${userId}`;
  let gameUserIds = JSON.parse(localStorage.getItem(storageKey));
  if (gameUserIds) {
    gameUserIds.push(gameUserId);
  } else {
    gameUserIds = [gameUserId];
  }

  localStorage.setItem(storageKey, JSON.stringify(gameUserIds));
};
