import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { setUserIdInGame } from "../utils"
import "./Home.css";

const Home = ({ db }) => {
  const [displayName, setDisplayName] = useState(
    window.localStorage.getItem("displayName") || ""
  );
  const [gameName, setGameName] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const startGame = (e) => {
    e.preventDefault();

    // It checks on browser side, but just to be sure
    if (!gameName || !displayName) return;

    setLoading(true);

    window.localStorage.setItem("displayName", displayName);

    db.collection("games")
      .add({
        name: gameName,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        voting: true
      })
      .then((gameRef) =>
        db.collection(`games/${gameRef.id}/users`).add({
          name: displayName,
          isAdmin: true,
          vote: "none"
        })
      )
      .then((userRef) => {
        setUserIdInGame(userRef.parent.parent.id, userRef.id)
        history.push(`/game/${userRef.parent.parent.id}`);
      })
      .catch (e => {
          console.error(e)
          setLoading(false)
      })
  };

  return (
    <div className="Home">
      <main className="MainContainer">
        <div className="FormContainer">
          <h1><span>♠️ My Planning Poker</span></h1>
          <p>
            Agile{" "}
            <a href="https://en.wikipedia.org/wiki/Planning_poker" target="_blank" rel="noreferrer">
              scrum poker
            </a>{" "}
            estimation made simple,
            <br />
            and 100% free 🎉
          </p>

          <form onSubmit={startGame}>
            <input
              required
              type="text"
              placeholder="Your display name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />

            <input
              required
              type="text"
              placeholder="Game name"
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
            />

            <div className="SubmitContainer">
              {loading && <img src="loading.svg" alt="loading ..." />}
              {!loading && <input type="submit" value="Start new game" />}
            </div>
          </form>
        </div>

        <div className="ImageContainer">
          <img src="/scrum.svg" alt="Scrum planning" />
        </div>
      </main>
      <footer>💌 contact@landab.com</footer>
    </div>
  );
};

export default Home;
