import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import 'firebase/analytics';
import 'firebase/firestore'
import "./index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home"
import Game from "./components/Game"

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDI3xhljmfB31Z0NUk1UZ7BBZWtVqo_sBg",
  authDomain: "poker-e71f1.firebaseapp.com",
  projectId: "poker-e71f1",
  storageBucket: "poker-e71f1.appspot.com",
  messagingSenderId: "398111131350",
  appId: "1:398111131350:web:60477d0c5a2b21959ceb77",
  measurementId: "G-ZLQ2YWZMM3"
});

firebaseApp.analytics()
const db = firebaseApp.firestore();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/game/:gameId">
          <Game db={db} />
        </Route>
        <Route path="/">
          <Home db={db} />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
