import React, { useState } from "react";
import { setUserIdInGame } from "../utils"
import "./Join.css";

const Join = ({ db, gameId, onUserJoin }) => {
  const [displayName, setDisplayName] = useState(localStorage.getItem("displayName") || "");
  const [loading, setLoading] = useState(false);

  const joinGame = (e) => {
    e.preventDefault();

    // It checks on browser side, but just to be sure
    if (!displayName) return;

    setLoading(true);

    localStorage.setItem("displayName", displayName);

    db.collection(`games/${gameId}/users`)
      .add({
        name: displayName,
        isAdmin: false,
        vote: "none"
      })
      .then((userRef) => {
        setUserIdInGame(userRef.parent.parent.id, userRef.id)
        onUserJoin();
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  return (
    <div className="Join">
      <main className="MainContainer">
        <div className="FormContainer">

          <form onSubmit={joinGame}>
            <input
              required
              type="text"
              placeholder="Your display name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              autoFocus
            />

            <div className="SubmitContainer">
              {loading && <img src="/loading.svg" alt="loading ..." />}
              {!loading && <input type="submit" value="Join game" />}
            </div>
          </form>
        </div>
      </main>
      <footer>💌 contact@landab.com</footer>
    </div>
  );
};

export default Join;
