import React from "react";
import "./UserCard.css";

// NoVote, Voted, Showing
const UserCard = ({ state, vote, displayName }) => {
  let stateStyleFront = {};
  let stateStyleBack = {};

  if (state === "Voted") {
    stateStyleFront = {
      borderStyle: "none",
      backgroundColor: "rgba(var(--mark-color), 1)",
    };
  }

  if (state === "Showing") {
    stateStyleFront = {
      borderStyle: "none",
      backgroundColor: "rgba(var(--mark-color), 1)",
      transform: "rotateY(-180deg)"
    };

    stateStyleBack = {
        transform: "rotateY(0deg)"
    }
  }

  return (
    <div className="UserCard">
      <div className="CardContainer">
        <div className="Front" style={stateStyleFront}></div>
        <div className="Back" style={stateStyleBack}>
          <span>{state === "Showing" && vote}</span>
        </div>
      </div>
      <div><span title={displayName}>{displayName}</span></div>
    </div>
  );
};

export default UserCard;
