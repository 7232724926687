import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Join from "./Join";
import UserCard from "./UserCard";
import { getUserIdForGame } from "../utils";
import "./Game.css";

const Game = ({ db }) => {
  let { gameId } = useParams();
  const history = useHistory();

  const [gameName, setGameName] = useState("");
  const [gameVoting, setGameVoting] = useState(false);
  const [users, setUsers] = useState([]);

  const [localGameUserId, setLocalGameUserId] = useState(
    getUserIdForGame(gameId)
  );

  //console.log(users, localGameUserId);

  useEffect(() => {
    const unsubscribe = db
      .collection(`games/${gameId}/users`)
      .onSnapshot((usersSnapshot) => {
        const users = [];
        usersSnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() });
        });
        setUsers(users);
      });

    return () => unsubscribe();
  }, [db, gameId]);

  useEffect(() => {
    const unsubscribe = db
      .collection(`games`)
      .doc(gameId)
      .onSnapshot((gameDoc) => {
        const game = gameDoc.data();
        setGameName(game.name);
        setGameVoting(game.voting);
      });

    return () => unsubscribe();
  }, [db, gameId]);

  const handleUserJoin = () => {
    setLocalGameUserId(getUserIdForGame(gameId));
  };

  const handleVote = (vote) => {
    if (!gameVoting) return;

    if (vote === users.find((u) => u.id === localGameUserId)?.vote) {
      vote = "none";
    }

    db.collection(`games/${gameId}/users`).doc(localGameUserId).update({
      vote,
    });
  };

  const handleGameVoting = (voting) => {
    db.collection("games").doc(gameId).update({
      voting,
    });

    if (voting) {
      db.collection(`games/${gameId}/users`)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              vote: "none",
            });
          });
        });
    }
  };

  // 0 = top, 1 = bottom, 2 = right, 3 = left
  const seatUsers = () => {
    const seats = [[], [], [], []];

    let sideIndex = 0;
    users.forEach((u) => {
      let userState = "NoVote";
      if (u.vote !== "none") {
        if (gameVoting) {
          userState = "Voted";
        } else {
          userState = "Showing";
        }
      }

      // Hide the actual id
      seats[sideIndex].push(
        <UserCard
          vote={u.vote}
          key={u.id.substring(0, 12)}
          state={userState}
          displayName={u.name}
        />
      );

      sideIndex += 1;
      if (sideIndex === 4) {
        sideIndex = 0;
      }
    });

    return seats;
  };

  const seatedUsers = seatUsers();

  const getCardAverage = () => {
    const votersToCount = users.filter(
      (u) => u.vote !== "none" && u.vote !== "?"
    );

    if (!votersToCount.length) {
      return ["?", "?"]
    }

    let average =
      votersToCount.reduce((acc, curr) => acc + +curr.vote, 0) /
      votersToCount.length;

    let closestCard = [0, 1, 2, 3, 5, 8, 13, 21, 34].reduce((acc, curr) =>
      Math.abs(curr - average) < Math.abs(acc - average) ? curr : acc
    );

    console.log(
      average,
      closestCard,
    );
    return [(Math.round(average * 10) / 10), closestCard];
  };

  if (!gameName || !users) {
    return (
      <div className="LoadingContainer">
        <img src="/loading.svg" alt="loading ..." />
      </div>
    );
  }

  // If no localUserId for this game
  if (!localGameUserId) {
    return <Join db={db} onUserJoin={handleUserJoin} gameId={gameId} />;
  }

  return (
    <div className="Game">
      <header className="Header">
        <div>
          <h2>
            <span>{gameName}</span>
          </h2>
        </div>
        <div>
          <button onClick={() => history.push("/")}>New game</button>
        </div>
        <p>💡 Invite players sharing the link of this page</p>
      </header>

      <main>
        <section className="Table">
          <div className="Container">
            <div className="LeftSeats">{seatedUsers[3]}</div>

            <div className="SideSeatsContainer">
              <div className="TopSeats">{seatedUsers[0]}</div>
              <div className="ActualTable">
                {users.find((u) => u.id === localGameUserId).isAdmin && (
                  <div className="ButtonContainer">
                    {!gameVoting && (
                      <button onClick={() => handleGameVoting(true)}>
                        Start new voting
                      </button>
                    )}
                    {gameVoting && (
                      <button
                        onClick={() => handleGameVoting(false)}
                        style={
                          users.find((u) => u.vote !== "none")
                            ? {}
                            : { visibility: "hidden" }
                        }
                      >
                        Show cards
                      </button>
                    )}
                  </div>
                )}
                <div className="Info">
                  {gameVoting && <p>Voting in progress ...</p>}
                  {!gameVoting && <>
                    <p className="Average">{getCardAverage()[0]}</p>
                    <UserCard
                      vote={getCardAverage()[1]}
                      state="Showing"
                      displayName="Closest"
                    />
                  </>
                    
                  }
                </div>
              </div>
              <div className="BottomSeats">{seatedUsers[1]}</div>
            </div>

            <div className="RightSeats">{seatedUsers[2]}</div>
          </div>
        </section>
        <section className="Stack" style={gameVoting ? {} : { visibility: "hidden" }}>
          <div className="Container">
            {["0", "1", "2", "3", "5", "8", "13", "21", "34", "?"].map(
              (point) => (
                <button
                  key={point}
                  className={
                    users.find((u) => u.id === localGameUserId)?.vote === point
                      ? "Card Selected"
                      : "Card"
                  }
                  onClick={() => handleVote(point)}
                >
                  {point}
                </button>
              )
            )}
          </div>
        </section>
      </main>
      <footer>💌 contact@landab.com</footer>
    </div>
  );
};

export default Game;
